// @flow
import { PageContent } from "@components";
import { LocalizedPreview } from "@containers";
import { Router } from "@reach/router";
import { graphql } from "gatsby";
import React from "react";

/**
 * Preview body, could possibly be more generalized
 */
const Body = ({ entryId, token, environment, markets }: any) => (
    <LocalizedPreview
        id="navigation-preview"
        entryId={entryId}
        token={token}
        environment={environment}
        markets={markets}
    >
        {(data, _, selectLocale) => (
            <PageContent
                title={data.title}
                publishedMarkets={markets}
                slug={data.slug}
                previewLocale={selectLocale}
                enableScrollTop={data.enableScrollTop}
                navigation={data}
            />
        )}
    </LocalizedPreview>
);

const NavigationPreview = ({ data }: *) => (
    <Router>
        <Body
            path="/preview/navigation/:entryId/:token"
            environment={data.site.siteMetadata.environment}
            markets={data.markets.nodes}
        />
    </Router>
);

export default NavigationPreview;

export const query = graphql`
    {
        site {
            siteMetadata {
                environment
            }
        }
        markets: allContentfulMarket {
            nodes {
                ...MarketGeneral
            }
        }
    }
`;
